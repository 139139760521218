<template>
    <div class="container">
        <div class="text-center mt-4 mb-4">
            <h4>Группы для дисциплины {{selectableCourses?.discipline?.educationDiscipline?.name}}
                - {{selectableCourses?.discipline?.educationDiscipline?.language?.native_name}}
                ({{selectableCourses?.discipline?.credit}} кредитов)
                Семестр {{selectableCourses?.discipline?.semester}}
            </h4>
            <h5 class="mt-2 mt-2">
                <span class="badge bg-secondary">Языковой центр</span>
            </h5>
        </div>
        <div class="mt-3 mb-4">
            <router-link
                    :to="`/selectable-flows?formation_education_program_id=${formation_education_program_id}&education_curriculum_id=${education_curriculum_id}`">
                Список потоков
            </router-link>
        </div>


        <div v-if="selectableCourses.flows.length">
            <div class="row border m-2 mb-4 rounded"
                 v-for="(flow, flowIndex) in selectableCourses.flows"
                 :key="flowIndex">
                <div class="col col-md-2 p-2">
                    <h5 class="p-3">{{flow?.selectable_flow_name}}</h5>
                </div>
                <div class="col col-md-10">
                    <div class="row p-2">
                        <div class="col col-md-3 p-2"
                             v-for="(group, groupIndex) in flow.groups"
                             :key="groupIndex">

                            <div>
                                <div>
                                    <div v-if="group.id">
                                        <Button :label="group?.selectable_group_name || 'Нет названия группы'"
                                                class="p-button-secondary p-button-text p-mr-2"
                                                @click="openEditGroupOfStudentsModal(group.selectable_group_id)"/>
                                    </div>
                                    <div v-else>{{group?.selectable_group_name || 'Нет названия группы'}}</div>
                                </div>
                                <div>
                                    {{group?.language_level}}&nbsp;
                                    <span class="col" v-if="group.id">
                                        <i class="pi pi-star"
                                           :style="{ color: group.mdl_course_id ? '#6ebe71' : '#fdd87d' }"></i>
                                    </span>
                                </div>
                                <div>{{group?.pps_lastname+' '+group?.pps_firstname}}</div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>


            <div class="text-center mt-2 mb-4">
                <button v-if="saveVisibleForRole" type="button" class="btn btn-primary m-1" @click="saveCourse">
                    Сохранить курсы
                </button>
                <button v-if="saveVisibleForRole" type="button" class="btn btn-primary m-1" @click="comparedStudents">
                    Проверить привязку студентов
                </button>
                <button v-if="uploadToMoodleVisibleForRole" type="button" class="btn btn-primary m-1"
                        @click="createMoodleCourse"
                        :disabled="publishToMoodle">
                    <span v-if="publishToMoodleBtnDisabled" class="spinner-grow spinner-grow-sm" role="status"
                          aria-hidden="true"></span>
                    {{ publishToMoodleBtnDisabled ? 'Публикация в Moodle' : 'Опубликовать в Moodle' }}
                </button>
                <div class="mt-3 mb-4">Перед публикацией в Moodle надо проверить привязку студентов</div>

            </div>

            <hr>
            <div class="mt-4 mb-3" v-if="selectableCourses.studentsNotAssignedToGroups.length">
                <h4 class="text-center mt-4 mb-3">Список неприкрепленных студентов</h4>
                <div v-for="(group, groupIndex) in selectableCourses.studentsNotAssignedToGroups"
                     :key="'groupIndex'+groupIndex"
                     class="row mb-2 border rounded p-2">
                    <div class="col col-md-4 p-4">
                        <h5>{{group?.selectable_flow_name||'Нет группы'}}</h5>
                    </div>
                    <div class="col col-md-8 p-3">
                        <div v-for="(student, studentIndex) in group.students"
                             :key="'studentIndex'+studentIndex"
                             class="row mb-4">
                            <div class="col col-md-4">
                                <span style="text-transform: uppercase">
                                    {{student?.last_name + ' ' + student?.first_name}}
                                </span>
                            </div>

                            <div class="col col-md-8">
                                <select class="form-control form-select"
                                        @input="changeSelectableGroup(student.student_data_id, $event)">
                                    <option v-for="(item, index) in [{name: 'Выберите группу', selectable_group_id: null},...selectableCourses.flowsWithoutGroupBy]"
                                            :value="item.selectable_group_id"
                                            :key="index"
                                            :selected="false">
                                        <span v-if="item.selectable_group_id">
                                            {{item?.selectable_flow_name}} {{item?.pps_lastname}} {{item?.pps_firstname}} {{item?.selectable_group_name}} ({{item?.language_level}})
                                        </span>
                                        <span v-else>
                                            {{item?.name}}
                                        </span>

                                    </option>
                                </select>
                            </div>


                        </div>
                    </div>

                </div>
                <div class="text-center mt-2 mb-4">
                    <button v-if="saveVisibleForRole" type="button" class="btn btn-primary m-1 mb-4"
                            @click="attachStudents">
                        Прикрепить студентов
                    </button>
                </div>

            </div>


        </div>


        <Dialog header="Список прикрепленных студентов" v-model:visible="displayEditGroupOfStudentsModal" :modal="true"
                :style="{width: '50vw'}">
            <div class="">
                <div class="p-2 text-center">
                    <h5>{{openGroupData?.selectable_flow_name}} - {{openGroupData?.selectable_group_name}}
                        ({{openGroupData?.pps_lastname+' '+openGroupData?.pps_firstname}})
                        ({{openGroupData?.language_level}})</h5>
                </div>
                <div class="p-3">
                    <div v-for="(student, studentIndex) in selectableCourses.studentsForGroup"
                         :key="studentIndex"
                         class="row mb-4">

                        <div class="col col-md-4">
                           <span style="text-transform: uppercase">
                                    {{student?.last_name + ' ' + student?.first_name}}
                           </span>
                        </div>

                        <div class="col col-md-8">
                            <select class="form-control form-select"
                                    @input="changeOfGroupForAssignedStudent(student.student_data_id, $event)">
                                <option v-for="(item, index) in selectableCourses.flowsWithoutGroupBy"
                                        :value="item.selectable_group_id"
                                        :key="index"
                                        :disabled="openGroupData.mdl_course_id"
                                        :selected="item.selectable_group_id==student.selectable_groups_id">
                                    {{item?.selectable_flow_name}} {{item?.pps_lastname+' '+item?.pps_firstname}}
                                    {{item?.selectable_group_name}} ({{item?.language_level}})
                                </option>
                            </select>
                        </div>

                    </div>
                </div>
            </div>
            <template #footer>
                <Button label="Отмена" icon="pi pi-times" class="p-button-text" @click="closeEditGroupOfStudentsModal"/>
                <Button label="Изменить группы у студентов" icon="pi pi-check" @click="changeStudentGroups"/>
            </template>
        </Dialog>


    </div>
</template>

<script>

    import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
    import {decryptCheckRole} from "@/utils/helpers/decryptedRoles.helpers";

    export default {
        name: "BindSelectableGroups",
        data() {
            return {
                formation_education_program_id: +this.$route.query.formation_education_program_id || 0,
                education_curriculum_id: +this.$route.query.education_curriculum_id || 0,
                publishToMoodleBtnDisabled: false,
                displayEditGroupOfStudentsModal: false,
                openGroupData: null
            }
        },
        computed: {
            ...mapState('bindSelectableGroups', ['selectableCourses']),
            saveVisibleForRole() {
                return decryptCheckRole('admin') || decryptCheckRole('departmentHead') || decryptCheckRole('officeRegistration') || decryptCheckRole('officeRegistrationHead')
            },
            uploadToMoodleVisibleForRole() {
                //officeRegistration officeRegistrationHead
                return decryptCheckRole('admin') || decryptCheckRole('departmentHead') || decryptCheckRole('officeRegistration') || decryptCheckRole('officeRegistrationHead')
            },
            publishToMoodle() {
                return (this.selectableCourses.flowsWithoutGroupBy.filter(i => i.mdl_course_id).length && !this.selectableCourses.flowsWithoutGroupBy.filter(i => i.id && !i.mdl_course_id).length)
                    || this.publishToMoodleBtnDisabled || !this.selectableCourses.isStudentsAssigned
            }

        },

        methods: {
            ...mapActions('bindSelectableGroups', [
                'GET_DISCIPLINE_BY_FORMATION_EDUCATION_PROGRAM',
                'GET_SELEACTABLE_FLOWS_BY_FORMATION_AND_CURRICULUM',
                'GET_COMPARED_STUDENTS',
                'POST_SELECTABLE_GROUP_STUDENTS',
                'POST_EDUCATION_COURSE',
                'PUT_EDUCATION_COURSE',
                'UPLOAD_TO_MOODLE',
                'GET_STUDENTS_BY_SELEACTABLE_GROUP_ID',
                'PUT_STUDENT_SELECTABLE_GROUPS'

            ]),
            ...mapMutations('bindSelectableGroups', [
                'CHANGE_GROUP',
                'SET_STUDENTS_NOT_ASSIGNED_TO_GROUPS_EMPTY',
                'CHANGE_OF_GROUP_FOR_ASSIGNED_STUDENT'
            ]),
            async openEditGroupOfStudentsModal(selectable_group_id) {
                this.openGroupData = this.selectableCourses.flowsWithoutGroupBy.find(i => i.selectable_group_id == selectable_group_id)
                await this.GET_STUDENTS_BY_SELEACTABLE_GROUP_ID(selectable_group_id)
                this.displayEditGroupOfStudentsModal = true
            },
            closeEditGroupOfStudentsModal() {
                this.displayEditGroupOfStudentsModal = false
            },
            changeOfGroupForAssignedStudent(student_data_id, e) {
                let selectable_groups_id = e.target.value
                this.CHANGE_OF_GROUP_FOR_ASSIGNED_STUDENT({student_data_id, selectable_groups_id})
            },

            async changeStudentGroups() {
                const {success} = await this.PUT_STUDENT_SELECTABLE_GROUPS()
                if (success) {
                    this.$message({text: 'Изменение произошло успешно'})
                }
                this.closeEditGroupOfStudentsModal()
            },
            changeSelectableFlowsData(property, e, val = 'value') {
                let value = e.target[val]
                this.CHANGE_SELECTABLE_FLOW_DATA({property, value})
            },
            changeSelectableGroup(student_data_id, e) {
                let selectable_groups_id = e.target.value
                this.CHANGE_GROUP({student_data_id, selectable_groups_id})

                console.log(student_data_id, selectable_groups_id, 'changeSelectableGroup')
            },
            async comparedStudents() {
                await this.GET_COMPARED_STUDENTS({
                    formation_education_program: this.formation_education_program_id,
                    education_curriculum_id: this.education_curriculum_id
                })
            },
            async attachStudents() {
                let students = this.selectableCourses.studentsNotAssignedToGroupsWithoutGroupBy.filter(i => i.selectable_groups_id)
                if (students.length) {
                    await this.POST_SELECTABLE_GROUP_STUDENTS(students)
                    this.SET_STUDENTS_NOT_ASSIGNED_TO_GROUPS_EMPTY()
                    this.$message({text: 'Студенты успешно прикреплены'})
                    console.log(students)
                }

            },
            async saveCourse() {
                let courses = [];
                for (let item of this.selectableCourses.flows) {
                    courses = [...courses, ...item.groups]
                }
                courses = JSON.parse(JSON.stringify(courses))

                const coursesCreate = courses.filter(i => i.pps_id && !i.id && !i.mdl_course_id && i.selectable_group_id)
                    .map(i => ({
                        formation_education_program_id: this.formation_education_program_id,
                        education_curriculum_id: this.education_curriculum_id,
                        lecture_pps_id: i.pps_id,
                        seminar_pps_id: i.pps_id,
                        selectable_group_id: i.selectable_group_id

                    }))


                if (coursesCreate.length) {
                    await this.POST_EDUCATION_COURSE(coursesCreate)
                }

                const coursesUpdate = courses.filter(i => i.pps_id && i.id && !i.mdl_course_id && i.selectable_group_id)
                    .map(i => ({
                        formation_education_program_id: this.formation_education_program_id,
                        education_curriculum_id: this.education_curriculum_id,
                        lecture_pps_id: i.pps_id,
                        seminar_pps_id: i.pps_id,
                        selectable_group_id: i.selectable_group_id,
                        id: i.id
                    }))

                if (coursesUpdate.length) {
                    await this.PUT_EDUCATION_COURSE(coursesUpdate)
                }

                if (coursesCreate.length || coursesUpdate.length) {
                    await this.GET_SELEACTABLE_FLOWS_BY_FORMATION_AND_CURRICULUM({
                        formation_education_program: this.formation_education_program_id,
                        education_curriculum_id: this.education_curriculum_id
                    })
                    this.$message({text: 'Данные успешно сохранились'})
                }

                console.log(coursesCreate, 'coursesCreate')
                console.log(coursesUpdate, 'coursesUpdate')
            },
            async createMoodleCourse() {
                let courses = [];
                for (let item of this.selectableCourses.flows) {
                    courses = [...courses, ...item.groups]
                }

                courses = JSON.parse(JSON.stringify(courses.filter(i => i.id && !i.mdl_course_id && i.lecture_pps_id && i.seminar_pps_id)))

                if (courses.length) {
                    this.publishToMoodleBtnDisabled = true
                    for (let c of courses) {
                        await this.UPLOAD_TO_MOODLE([c])
                    }
                    await this.GET_SELEACTABLE_FLOWS_BY_FORMATION_AND_CURRICULUM({
                        formation_education_program: this.formation_education_program_id,
                        education_curriculum_id: this.education_curriculum_id
                    })
                    this.publishToMoodleBtnDisabled = false
                    this.$message({text: 'Данные успешно опубликованы в Moodle'})
                } else {
                    this.$message({text: ' '})
                }

            }

        },
        async mounted() {
            this.SET_STUDENTS_NOT_ASSIGNED_TO_GROUPS_EMPTY()
            await this.GET_DISCIPLINE_BY_FORMATION_EDUCATION_PROGRAM(this.formation_education_program_id)
            await this.GET_SELEACTABLE_FLOWS_BY_FORMATION_AND_CURRICULUM({
                formation_education_program: this.formation_education_program_id,
                education_curriculum_id: this.education_curriculum_id
            })
        }
    }
</script>

<style scoped>

</style>